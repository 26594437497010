<template>
    <div>
        <Quote title="Bu yerda siz ommaviy oferta bilan tanishib chiqishingiz mumkin"/>
        <GoBack next="Ommaviy oferta"/>
        <div class="row justify-content-center">
            <div class="col-8 mt-3">
                <h4 class="mt-3 mb-3 text-center">
                    OOO "The Kadirov Group" kompaniyasining onlayn darslari uchun ommaviy oferta
                </h4>
                <p class="mt-1">
                    <span style="font-weight: bold">1.</span>
                    Barcha darslar zoom, yoki boshqa dasturlar orqali o'tiladi.
                </p>
                <p class="mt-1">
                    <span style="font-weight: bold">2.</span>
                    Darslardan maqsad dasturlashni o'rganish.
                </p>
                <p class="mt-1">
                    <span style="font-weight: bold">3.</span>
                    Ushbu darslarda siz dasturlashni o'rganishingiz mumkin, lekin kompaniyamiz sizni
                    ish bilan ta'minlab berish mas'uliyatini olmaydi.
                </p>
                <p class="mt-1">
                    <span style="font-weight: bold">4.</span>
                    Biror sababga ko'ra darslarni davom ettira olmasangiz, bir marta keyingi guruhda davom
                    ettirishingiz mumkin bo'ladi. Buning uchun administratorga murojaat qilishingiz kerak bo'ladi.
                </p>
                <p class="mt-1">
                    <span style="font-weight: bold">5.</span>
                    Agar, kursning to'liq to'lovini amalga oshirib, 5 (besh) kun ichida o'qimaslikka qaror qilsangiz,
                    to'langan summa qaytarib beriladi. Lekin, kompaniyada sizning to'lovingiz uchun turli xarajatlar
                    bo'lgani uchun, qaytariladigan summadan 10% ushlab qolinadi. To'lov amalga oshirilgach,
                    5 (besh) kundan so'ng pulni qaytarib olish mumkin emas. <br/>
                    Agar, hamkor kompaniyalarimiz orqali kursga oyma-oy to'lov uchun shartnoma qilib qo'shilgan
                    bo'lsangiz, pulni qaytarish imkoni mavjud emas.
                </p>
                <p class="mt-1">
                    <span style="font-weight: bold">6.</span>
                    O'qituvchi belgilangan vaqt tartibida dars o'tish imkoniyatiga ega bo'lmasa, darsni guruh
                    o'quvchilari bilan kelishgan holda boshqa kunga yoki keyingi dars sanasiga qoldirishga haqli.
                    Lekin, darslar o'qituvchi tomonidan kurs davomida 10 (o'n) martadan ko'p ko'chirilishi yoki
                    qoldirilishi mumkin emas (O'quvchilarning davomati va darslarga tayyor bo'lmasdan qatnashganda,
                    darsni o'qituvchi tomonidan qoldirilishi bundan mustasno).
                </p>
                <p class="mt-1">
                    <span style="font-weight: bold">7.</span>
                    Ofertani qabul qilish bilan birga, barcha topshiriqlarni o'z vaqtida bajarish, darslarni
                    jiddiy sababsiz qoldirmaslik majburiyatini o'z zimmangizga olgan bo'lasiz.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import GoBack from "@/components/GoBack";
import Quote from "@/components/Quote";
import {mapActions} from "vuex";

export default {
    name: "RegistrationPage",
    components: {GoBack, Quote},
    methods: {
        ...mapActions(['fetchPushUser', 'fetchToken']),
        registration() {
            this.fetchPushUser(this.forms)
                .then(() => {
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 5000)
                    this.login()
                })
                .catch(() => {
                    this.$refs['modal-unsuccess'].show()
                    setTimeout(this.errorModal, 5000)
                    console.log('registration da xato')
                })
                .finally(() => {

                })
        },
        login() {
            this.fetchToken({email: this.forms.email, password: this.forms.password})
                .then(() => {
                    this.$router.push('/')
                })
                .catch(() => {
                    console.log('loginda token olishda xato')
                })
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
        },
        errorModal() {
            this.$refs['modal-unsuccess'].hide()
        }
    },
    data() {
        return {
            forms: {
                email: "",
                password: "",
                givenName: "",
                familyName: "",
                phoneNumber: "",
                telegramNumber: "",
                roles: ["ROLE_USER"]
            }
        }
    },
}
</script>

<style scoped>

</style>
